import logo from './logo.svg';
import './App.css';
import { Login } from "./delete_acount/login_user.jsx";

function App() {
  return (
    <div className="App">
     <Login />
     
    </div>
  );
}

export default App;
