import "bootstrap/dist/css/bootstrap.css";
import React, { useState,useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// ... (other import statements)

const MySwal = withReactContent(Swal);
// const baseurl = "http://localhost:8000";
// const baseurl = "http://13.48.213.220";
const baseurl = "https://api-elli-cares.com";

export function PassInputs({ params, onChange }) {
  const [showPassword, setShowPassword] = useState(false);

  const handleInputChange = (e) => {
    onChange(e.target.value);
  };
useEffect(() => {
  document.title = "Elli Delete Acount";
}, []);

  return (
    <div className="container mt-4">
      <div className="mb-3 row">
        <label htmlFor={params.id} className="col-form-label text-start">
          {params.name}
        </label>
        <div className="col input-group">
          <input
            type={showPassword ? "text" : "password"}
            className="form-control"
            id={params.id + "input"}
            name={params.name}
            onChange={handleInputChange}
          />
          <button
            className="btn btn-outline-secondary"
            type="button"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? "Hide" : "Show"}
          </button>
        </div>
      </div>
    </div>
  );
}

export function UserInputs({ params, onChange }) {
  return (
    <div className="container mt-4">
      <div className="mb-3 row">
        <label htmlFor={params.id} className="col-form-label text-start">
          {params.name}
        </label>
        <div className="col">
          <input
            type="text"
            className="form-control"
            id={params.id + "input"}
            name={params.name}
            onChange={(e) => onChange(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}

export function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleLogin = async () => {
    try {
      setLoading(true);

      const response = await axios.post(`${baseurl}/api/auth/login`, {
        email,
        password,
      });

      if (response.data.success) {
        setError("");

        // console.log(response.data);
        openDeleteAlert(response.data);
      } else {
        setError("Login failed. Please check your credentials.");
      }
    } catch (error) {
      console.log(error)
      if (error.response.data.message !==undefined)
       { console.error("API call error:", error.response.data.message);
      setError(error.response.data.message);}
    } finally {
      setLoading(false);
    }
  };

  const openDeleteAlert = (data) => {
    MySwal.fire({
      title: "Are you sure you want to delete your account?",
      text: "This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteAccount(data);
      }
    });
  };

  const handleDeleteAccount = async (data) => {
    try {
      let deleteurl;
      // Perform account deletion logic
      if (data.data.isLoved) {
        deleteurl = `${baseurl}/api/setting/deleteLovedAcount`;
      } else {
        deleteurl = `${baseurl}/api/setting/deleteUserAcount`;
      }
      //   console.log(data.data.token);
      const deleteResponse = await axios.get(deleteurl, {
        headers: {
          Authorization: `Bearer ${data.data.token}`,
          "Content-Type": "application/json", // Set Content-Type header
        },
      });
      if (deleteResponse.data.success) {
        // Successful deletion
        MySwal.fire("Deleted!", "Your account has been deleted.", "success");
        // Optionally, you can navigate to another page or show a success message.
      } else {
        setError("Failed to delete account. Please try again.");
      }
    } catch (error) {
      console.error("API call error:", error);
      setError("An error occurred during the deletion process.");
    }
  };

  return (
    <div>
      <div className="container mt-4">
        <h3 className="text-start">Elli Cares Delete Account Page</h3>
      </div>

      <UserInputs params={{ name: "email", id: "email" }} onChange={setEmail} />
      <PassInputs
        params={{ name: "password", id: "password" }}
        onChange={setPassword}
      />

      {error && <div className="text-danger">{error}</div>}

      <div className="container mt-3">
        <button className="btn btn-primary" onClick={() => handleLogin()}>
          {loading ? "Logging in..." : "  Delete Account"}
        </button>
      </div>
    </div>
  );
}
